<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>
        <v-col md="12">
          <v-data-table class="table" 
              :items="allPrompters.results"
              :headers="headers"
              :server-items-length="allPrompters.pagination.total"
              :options.sync="options"
              :loading="loading"
          >
            <template v-slot:item.actions="{ item }">
              <router-link :to="`/dashboard/prompter/${item._id}`">
                <v-icon class="icons-action">mdi-pencil</v-icon>
              </router-link>
              <v-icon
                class="icons-action"
                @click="deletePrompoterAct(item._id)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <div v-if="loading">
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Subemotion",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Prompter",
      addlink: "/dashboard/prompter/add",
    },
    loading: true,
    headers: [
      { text: 'Emotion', value: 'emotionName', sortable: false },
      { text: 'Message', value: 'message', sortable: false },
      { text: 'Action', value: 'actions', sortable: false, align: 'center' },
    ],
    options: {
      itemsPerPage: 5,
      page: 1
    },
  }),
  methods: {
    validate() {},
    ...mapActions(["getPrompters", "deletePrompter"]),
    async deletePrompoterAct(id) {
      confirm("Are you sure you want to delete this prompter?") &&
        (await this.deletePrompter(id));
    },
    async reloadPrompters() {
      this.loading = true;
      await this.getPrompters({
        limit: this.options.itemsPerPage,
        skip: (this.options.page - 1) * this.options.itemsPerPage,
      });
      this.loading = false;
    }
  },
  async created() {
    await this.reloadPrompters();
  },
  computed: {
    ...mapGetters(["allPrompters"]),
  },
  watch: {
    options: {
      async handler() {
        await this.reloadPrompters();
      },
      deep: true,
    }
  }
};
</script>